<template>
  <div
    class="shadow-md h-full transform hover:shadow-lg hover:-translate-y-1 transition-all duration-300 ease"
    :class="`border-t-6 border-brand-${color}-300`"
  >
    <div
      ref="target"
      class="h-full flex flex-col justify-between p-4 border border-t-0 border-gray-200"
    >
      <div>
        <h3 v-if="course.qualification" class="leading-tight mb-3">
          <span class="text-sm text-gray-800">
            {{ course.qualification.type }}
          </span>
          <span class="block text-xl">
            {{ dequalifyTitle(course.title) }}
          </span>
        </h3>

        <span class="text-base font-bold text-gray-700 mb-4">
          {{ course.id }}
        </span>

        <p class="text-xs mb-4">
          <template v-if="course.overview">
            {{ course.overview }}
          </template>
          <template v-else> No overview available </template>
        </p>

        <CourseCardAccordion
          label="Entry requirements"
          :items="entryRequirements"
          :color="color"
        />

        <CourseCardAccordion
          label="Career pathways"
          :items="course.careerPathwaysRollup"
          :color="color"
        />
      </div>

      <TafeButton
        component="nuxt-link"
        :to="{
          name: 'Course',
          params: {
            courseArea: $route.params.courseArea,
            courseId: course.id,
            courseSlug: $slugify(course.title, {
              lowercase: true
            })
          }
        }"
        :icon="{ name: 'IconChevronRight', side: 'right' }"
        type="secondary"
        class="self-end"
      >
        View course
      </TafeButton>
    </div>
  </div>
</template>
<script>
import useFilters from '~/composables/useFilters'
import { useElementVisibility } from '@vueuse/core'

export default {
  name: 'CourseCard',
  props: {
    course: {
      type: Object,
      default: () => {}
    },
    color: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { dequalifyTitle } = useFilters()
    const { gtag } = useGtag()
    const courseAreaSlug = useRoute().path?.split('/').pop()
    return {
      dequalifyTitle,
      gtag,
      courseAreaSlug
    }
  },
  data() {
    return {
      targetIsVisible: false
    }
  },
  computed: {
    entryRequirements() {
      return this.course.entryRequirements?.requirements || []
    },
    gtmData() {
      return {
        course_id: this.course?.id,
        course_name: this.course?.title,
        course_area: this.courseAreaSlug
      }
    }
  },
  watch: {
    targetIsVisible: {
      handler(newValue) {
        if (newValue) {
          this.gtag('event', 'courseImpressions', this.gtmData)
        }
      },
      once: true
    }
  },
  mounted() {
    this.targetIsVisible = useElementVisibility(this.$refs.target)
  }
}
</script>
